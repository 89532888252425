import Script from "next/script";
import React, { useState } from "react";
import style from "./FAQ.module.css";
import { escape } from "lodash";

function escapeChars(inputString) {
  var escapedString = "";
  for (var i = 0; i < inputString.length; i++) {
    var char = inputString.charAt(i);
    switch (char) {
      case "&":
        escapedString += "&amp;";
        break;
      case "<":
        escapedString += "&lt;";
        break;
      case ">":
        escapedString += "&gt;";
        break;
      case '"':
        escapedString += "&quot;";
        break;
      case "'":
        escapedString += "&#39;";
        break;
      default:
        escapedString += char;
        break;
    }
  }
  return escapedString;
}

function FAQ({ data, lang }) {
  const [opened, setOpened] = useState<any>(new Array(data.faq.length).fill(0));
  const updateElement = (index, newValue) => {
    setOpened((prevState) => {
      const updatedArray = new Array(data.faq.length).fill(0);
      updatedArray[index] = newValue;
      return updatedArray;
    });
  };

  const jsonLdData = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: data?.faq?.map((faq) => {
      return {
        "@type": "Question",
        name: faq.question ? faq.question[lang] : "",
        acceptedAnswer: {
          "@type": "Answer",
          text: faq.response ? faq.response[lang] : "",
        },
      };
    }),
  };

  return (
    <div className={style.container}>
      <Script
        id="json-ld-faq"
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonLdData) }}
      />
      <p className={style.sectionTitle}>{data.titre[lang]}</p>
      <div className={style.FAQContainer}>
        {data?.faq?.map((item, key) => {
          return (
            <div
              className={`${style.FAQElement} ${opened[key] === 1 ? style.questionActive : ''
                }`}
              key={key}
              onClick={() => {
                opened[key] === 0
                  ? updateElement(key, 1)
                  : updateElement(key, 0);
              }}
            >
              <div
                data-tracking="click"
                data-value=""
                className={`${style.question} `}
                style={{ flexDirection: lang == "ar" ? "row-reverse" : "row" }}
              >
                {/* <p>{escape(item.question[lang])}</p> */}
                <p
                  dangerouslySetInnerHTML={{
                    __html: escape(item.question[lang]),
                  }}
                ></p>
                {/* <p dangerouslySetInnerHTML={{ __html: escapeChars(item.question[lang])}}></p> */}
                {/* <p dangerouslySetInnerHTML={{ __html: escapeChars(item.question[lang])}}></p> */}
                {opened[key] === 0 ? (
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.86012 1.53386C4.60164 1.79389 4.60164 2.21383 4.86012 2.47386L10.3868 8.00053L4.86012 13.5272C4.59871 13.7886 4.59871 14.2124 4.86012 14.4739C5.12154 14.7353 5.54538 14.7353 5.80679 14.4739L11.8068 8.47386C12.0653 8.21384 12.0653 7.79389 11.8068 7.53386L5.80679 1.53386C5.68161 1.40765 5.51122 1.33666 5.33346 1.33666C5.1557 1.33666 4.9853 1.40765 4.86012 1.53386Z"
                      fill="#00111A"
                    />
                  </svg>
                ) : (
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 21 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M20.2002 1.29C19.8101 0.902276 19.1802 0.902276 18.7902 1.29L10.5002 9.58L2.21019 1.29C1.81806 0.897878 1.18231 0.897878 0.790185 1.29C0.398063 1.68212 0.398063 2.31788 0.790185 2.71L9.79018 11.71C10.1802 12.0977 10.8101 12.0977 11.2002 11.71L20.2002 2.71C20.3895 2.52223 20.496 2.26664 20.496 2C20.496 1.73336 20.3895 1.47777 20.2002 1.29Z"
                      fill="#4609F0"
                    />
                  </svg>
                )}
              </div>
              <div
                className={style.description}
              >
                <p
                  dangerouslySetInnerHTML={{
                    __html: escape(item.response[lang]),
                  }}
                ></p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default FAQ;
