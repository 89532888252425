import { GetStaticProps } from "next";
import { PreviewSuspense } from "next-sanity/preview";
import { EstimationImmobiliere } from "./../page/EstimationImmobiliere";
import { LazyPreviewPage } from "./../page/LazyPreviewPage";
import { LoadingScreen } from "./../page/LoadingScreen";
import { PAGE_DATA_QUERY } from "./../page/query";
import { PageData } from "./../page/types";
import { client } from "./../sanity/client";

interface PageProps {
  data: PageData | null;
  preview: boolean;
  slug?: string | null;
  token: string | null;
}

interface Query {
  [key: string]: string;
}

interface PreviewData {
  token?: string;
}

export const getStaticProps: GetStaticProps<
  PageProps,
  Query,
  PreviewData
> = async (ctx) => {
  let { params = {}, preview = false, previewData = {} } = ctx;
  params.slug = "new-home-page";
  if (preview && previewData.token) {
    return {
      props: {
        data: null,
        preview,
        slug: "new-home-page",
        token: previewData.token,
      },
    };
  }

  const data = await client.fetch<PageData | null>(PAGE_DATA_QUERY, params);

  return {
    props: {
      data,
      preview,
      slug: params?.slug.replace("/fr", "").replace("/ar", "") || null,
      token: null,
    },
  };
};

export default function Page(props: PageProps) {
  const { data, preview, token } = props;

  if (preview) {
    return (
      <PreviewSuspense
        fallback={<LoadingScreen>Loading preview…</LoadingScreen>}
      >
        <LazyPreviewPage slug={"new-home-page"} token={token} />
      </PreviewSuspense>
    );
  }
  if (data) {
    return <EstimationImmobiliere data={data} page={"new-home-page"} />;
  } else {
    return null;
  }
}
