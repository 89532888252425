import Head from "next/head";
import siteMetadata from "data/siteMetadata";
import useTranslation from "next-translate/useTranslation";
import formatUrl from "utils/FormatUrl";
import { canonicalUrl as formatCanonical } from "utils/FormatUrl";
const SanityHeadSeo = ({
  title,
  ogTitle,
  description,
  ogDescription,
  canonicalUrl,
  ogTwitterImage,
  ogType,
  children,
  ogImageUrl,
  structuredData,
}) => {
  const { t, lang } = useTranslation("");
  return (
    <Head>
      {/* //basic metadata */}
      <title>{title}</title>
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"
      />
      <meta name="description" content={description} />
      {/* //twitter metadata */}
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content={siteMetadata.twitterHandle} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta
        name="twitter:image"
        content={
          ogImageUrl
            ? ogImageUrl
            : "https://storage.googleapis.com/headers-agenz/Logo%20300_%20300%20px.jpg"
        }
      />
      {/* //canonical link */}
      {canonicalUrl ? <link rel="canonical" href={formatCanonical(canonicalUrl, lang)} /> : null}
      {canonicalUrl ? <link rel="alternate" hrefLang="fr" href={formatCanonical(canonicalUrl, "fr")} /> : null}
      {canonicalUrl ? <link rel="alternate" hrefLang="ar" href={formatCanonical(canonicalUrl, "ar")} /> : null}
      {/* //open graph metadata */}
      <meta property="og:locale" content={lang == "fr" ? "fr_FR" : "ar_AR"} />
      <meta property="og:site_name" content={"agenz"} />
      <meta property="og:type" content={ogType} />
      <meta property="og:title" content={ogTitle} />
      <meta property="og:description" content={ogDescription} />
      <meta
        property="og:image"
        // itemProp="image"
        content={
          ogImageUrl
            ? ogImageUrl
            : "https://storage.googleapis.com/headers-agenz/Logo%20300_%20300%20px.jpg"
        }
      />
      {canonicalUrl ? <meta property="og:url" content={formatCanonical(canonicalUrl, lang)} /> : null}
      <meta property="og:updated_time" content="1440432930" />
      <meta property="og:image:width" content="256" />
      <meta property="og:image:height" content="256" />
      {/* // MS Tile - for Microsoft apps */}
      <meta name="msapplication-TileImage" content={ogImageUrl}></meta>
      {structuredData ? (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={structuredData}
        />
      ) : (
        <></>
      )}
      {children}
    </Head>
  );
};

export default SanityHeadSeo;
