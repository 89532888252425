import React from "react";
import style from "./style.module.css";

function index({ number, message }) {
  return (
    <div
      onClick={() => {
        window.open(
          `https://api.whatsapp.com/send?phone=${number}&text=${message}`,
          "_blank"
        );
      }}
      className={style.container}
    >
      <div className={style.icon}>
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M21.6526 17.8813C21.2914 17.7014 19.5156 16.8317 19.1845 16.7118C18.8535 16.5919 18.6127 16.5319 18.3719 16.8917C18.1311 17.2516 17.4389 18.0612 17.2282 18.3012C17.0175 18.541 16.8069 18.5711 16.4457 18.3911C16.0845 18.2112 14.9207 17.8316 13.5411 16.6069C12.4674 15.6539 11.7425 14.4766 11.5317 14.1168C11.3211 13.7569 11.5093 13.5624 11.6902 13.3831C11.8526 13.2222 12.0513 12.9634 12.2319 12.7534C12.4125 12.5435 12.4727 12.3936 12.5931 12.1537C12.7135 11.9138 12.6533 11.7038 12.563 11.5239C12.4727 11.344 11.7504 9.57469 11.4494 8.85497C11.1562 8.15406 10.8584 8.2489 10.6367 8.23791C10.4263 8.22747 10.1853 8.22526 9.94446 8.22526C9.70366 8.22526 9.3124 8.31526 8.98127 8.67505C8.6502 9.0349 7.71714 9.90462 7.71714 11.6738C7.71714 13.4431 9.0114 15.1524 9.192 15.3924C9.3726 15.6323 11.7389 19.2631 15.3622 20.8201C16.2239 21.1905 16.8967 21.4116 17.4212 21.5774C18.2865 21.8509 19.0739 21.8123 19.6962 21.7198C20.3902 21.6166 21.8332 20.8501 22.1342 20.0105C22.4352 19.1708 22.4352 18.4511 22.3449 18.3012C22.2546 18.1512 22.0138 18.0612 21.6526 17.8813ZM15.063 26.8369H15.0581C12.9022 26.8361 10.7876 26.2597 8.94293 25.1702L8.50418 24.911L3.95685 26.0982L5.17063 21.6858L4.88491 21.2335C3.68224 19.3297 3.04705 17.1293 3.04796 14.8699C3.05059 8.27718 8.44044 2.91351 15.0677 2.91351C18.2769 2.91475 21.2935 4.16015 23.5619 6.42023C25.8304 8.68038 27.0789 11.6846 27.0777 14.8796C27.075 21.4729 21.6852 26.8369 15.063 26.8369ZM25.2862 4.70292C22.557 1.98365 18.9275 0.485392 15.0606 0.48387C7.093 0.48387 0.608313 6.93707 0.605119 14.8691C0.604078 17.4046 1.26968 19.8797 2.53463 22.0613L0.483887 29.5161L8.14694 27.5157C10.2583 28.6618 12.6355 29.2657 15.0548 29.2667H15.0607H15.0608C23.0276 29.2667 29.5129 22.8127 29.5161 14.8806C29.5176 11.0366 28.0155 7.42212 25.2862 4.70292Z" fill="white" />
        </svg>
      </div>
    </div>
  );
}

export default index;
