import useTranslation from "next-translate/useTranslation";
import React from "react";
import formatUrl from "utils/FormatUrl";
import style from "./cards.module.css";
import Image from "next/image";

function index({
  image,
  titre,
  texte,
  lien1,
  href1,
  lien2,
  href2,
  button_text,
  button_link,
  t,
  lpHome,
}: {
  image: any;
  titre: string;
  texte: string;
  lien1: string;
  href1: string;
  lien2: string;
  href2: string;
  button_text: string;
  button_link: string;
  t?: any;
  lpHome?: true;
}) {
  const { lang } = useTranslation("common");
  //   "data cards:",button_link);
  return (
    <>
      <div className={`${style.card} ${lpHome ? style.cardHome : null}`}>
        <div className={style.img}>
          {/* <img src={image} alt={titre} width={205} height={255} /> */}
          <Image unoptimized src={image} alt={titre} width={205} height={255} />
        </div>
        <div className={style.content}>
          <div className={style.texts}>
            <div className={style.titleDiv}>
              <p className={style.title}>{titre}</p>
            </div>
            <div className={style.descDiv}>
              <p className={style.description}>
                <span>
                  {lien1 != null && (
                    <>
                      <a
                        rel={
                          href1?.includes("list") ||
                          href1?.includes("map") ||
                          href1?.includes("?") ||
                          href1?.includes("conditions") ||
                          href1?.includes("contact")
                            ? "noindex, nofollow, noreferrer"
                            : "dofollow"
                        }
                        className={style.link}
                        href={formatUrl(href1, lang)}
                      >
                        {lien1}{" "}
                      </a>
                      {t ? t("ou ") : "ou "}
                    </>
                  )}
                  {lien2 != null && (
                    <a
                      data-tracking="click"
                      data-value=""
                      className={style.link}
                      rel={
                        href2?.includes("list") ||
                        href2?.includes("map") ||
                        href2?.includes("?") ||
                        href2?.includes("conditions") ||
                        href2?.includes("contact")
                          ? "noindex, nofollow, noreferrer"
                          : "dofollow"
                      }
                      href={formatUrl(href2, lang)}
                    >
                      {lien2}{" "}
                    </a>
                  )}
                </span>
                {texte}
              </p>
            </div>
          </div>
          <div className={style.button}>
            <a
              rel={
                button_link?.includes("list") ||
                button_link?.includes("map") ||
                button_link?.includes("?") ||
                button_link?.includes("conditions") ||
                button_link?.includes("contact")
                  ? "noindex, nofollow, noreferrer"
                  : "dofollow"
              }
              className={style.btn}
              href={formatUrl(button_link, lang)}
            >
              {button_text}
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default index;
