import React from "react";
import styles from "./linksComponent.module.css";
import formatUrl from "utils/FormatUrl";

const index = (props) => {
  return (
    <div className={styles.frameParent}>
      <div className={styles.frameGroup}>
        {props?.data?.map((elem) => {
          return (
            <>
              <div
                className={`${styles.frameDiv} ${props.lang === "ar" ? styles.frameDivAr : ""
                  }`}
              >
                <div className={styles.louerACasablancaWrapper}>
                  <div className={styles.louerACasablanca1}>
                    {elem.title ? elem.title[props.lang] : null}
                  </div>
                </div>
                {props.isFlex ? (
                  <div className={styles.ArrayContainer}>
                    {Array.from(
                      { length: Math.ceil(elem.annoncesLinks.length / 5) },
                      (_, index) => (
                        <div
                          className={styles.linksParent}
                          style={{ flex: "1 1" }}
                        >
                          {elem.annoncesLinks
                            .slice(index * 5, index * 5 + 5)
                            .map((e) => {
                              return (
                                <div className={styles.links}>
                                  <a
                                    data-tracking="click"
                                    data-value=""
                                    rel={
                                      e.buttonLink?.includes("list") ||
                                        e.buttonLink?.includes("map") ||
                                        e.buttonLink?.includes("?") ||
                                        e.buttonLink?.includes("conditions") ||
                                        e.buttonLink?.includes("contact")
                                        ? "noindex, nofollow, noreferrer"
                                        : "dofollow"
                                    }
                                    href={formatUrl(e.buttonLink, props.lang)}
                                    className={styles.locationAppartements10}
                                  >
                                    {e.button ? e.button[props.lang] : null}
                                  </a>
                                </div>
                              );
                            })}
                        </div>
                      )
                    )}
                  </div>
                ) : (
                  <div className={styles.linksParent}>
                    {elem.annoncesLinks?.map((e) => {
                      return (
                        <>
                          <div className={styles.links}>
                            <a
                              data-tracking="click"
                              data-value=""
                              rel={
                                e.button
                                  ? e.button[props.lang]?.includes("list") ||
                                    e.button[props.lang]?.includes("map") ||
                                    e.button[props.lang]?.includes("?") ||
                                    e.button[props.lang]?.includes(
                                      "conditions"
                                    ) ||
                                    e.button[props.lang]?.includes("contact")
                                    ? "noindex, nofollow, noreferrer"
                                    : "dofollow"
                                  : "noindex, nofollow, noreferrer"
                              }
                              href={formatUrl(e.buttonLink, props.lang)}
                              className={styles.locationAppartements10}
                            >
                              {e.button ? e.button[props.lang] : null}
                            </a>
                          </div>
                        </>
                      );
                    })}
                  </div>
                )}
              </div>
            </>
          );
        })}
      </div>
    </div>
  );
};

export default index;
